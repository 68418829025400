
.main-content {
	min-height: 400px;
}

.homepage-banner {
	background-size: cover;
	h1 {
		color: #ffffff; 
		text-transform: uppercase; 
		line-height: 55px;
	}
	h5 {
		font-size: 20px; 
		font-weight: 500; 
		font-family: 'ArialNarrowRegular'; 
		color: #ffffff; 
		max-width: 650px; 
		line-height: 30px;
	}
	.gold-button-with-arrow {
		font-family: 'Arial', sans-serif; 
		font-weight: 600; 
		text-transform: uppercase; 
		margin-top: 60px;
	}
}

.rectangle-img.rectangle-right-img {
	margin: 0px 0px 30px 55px;
}
/*.rectangle-img {
	margin-bottom: 0px;
	.vc_single_image-wrapper {
		position: relative;
		margin-top: -60px;
		margin-bottom: -40px;
		&:before {
			content: '';
			width: 90px;
			height: 90px;
			position: relative;
			top: 65px;
			left: 424px;
			background: $red;
			display: inline-block;
			z-index: -1;
		}
		&:after {
			content: '';
			width: 112px;
			height: 112px;
			position: relative;
			bottom: 58px;
			left: -55px;
			background: url("../images/img-dots.png") no-repeat;
			display: inline-block;
			z-index: -1;
		}
	}
}*/
.half-left-image-section {
	margin-bottom: 60px;
	@media (max-width: 1199px) {
		margin-bottom: 0px;
		.rectangle-img {
			margin-right: 50px;
			margin-left: 20px;
		}
	}
	@media (max-width: 1199px) {
		.rectangle-img {
			margin-right: 35px;
			margin-left: 50px;
		}
	}
	@media (max-width: 767px) {
		.rectangle-img {
			margin-bottom: 30px;
		}
	}
}
.half-right-image-section {
	margin-bottom: 40px !important;
	@media (min-width: 1200px) {
		margin-bottom: 100px !important;
	}
	@media (max-width: 1024px) {
		padding-left: 0px;
		padding-right: 40px;
	}
	@media (max-width: 767px) {
		padding-left: 0px;
		padding-right: 40px;
		margin-bottom: 70px !important;
	}
}
.content-half-right-section {
	.vc_column-inner {
		padding-top: 90px !important;
		@media (max-width: 1024px) {
			padding-top: 60px !important;
		}
	}
	@media (max-width: 767px) {
		.rectangle-img {
			margin-bottom: 70px;
		}
	}
}
.content-half-left-section {
	.vc_column-inner {
		padding-top: 50px !important;
		@media (max-width: 1024px) {
			padding-top: 30px !important;
		}
	}
}
.rectangle-img {
	margin-bottom: 0px;
	.vc_single_image-wrapper {
		position: relative;
		margin-top: 30px;
		margin-bottom: -40px;
		&:before {
			content: '';
			width: 90px;
			height: 90px;
			position: absolute;
			top: -30px;
			right: -33px;
			background: $red;
			display: inline-block;
			z-index: -1;
			@media (max-width: 1024px) {
				width: 80px;
				height: 80px;
				bottom: -25px;
				right: -25px;
			}
		}
		&:after {
			content: '';
			width: 112px;
			height: 112px;
			position: absolute;
			bottom: -55px;
			left: -55px;
			background: url("../images/img-dots.png") no-repeat;
			display: inline-block;
			z-index: -1;
			@media (max-width: 1024px) {
				width: 85px;
				height: 85px;
				bottom: -35px;
				left: -38px;
				background-size: 80px;
			}
		}
	}
}
.seperator-heading {
	h4 {
		font-size: 32px;
		color: $black;
		font-family: 'ArialNarrow';
	}
}
/*-- Image Slider --*/
.wpcp-carousel-section.slick-slider {
	padding-bottom: 65px;
}
.slick-prev.slick-arrow {
	.fa {opacity: 0 !important;}
	background: $gold url('../images/arrow-left.png') no-repeat !important;
	background-position: 8px 7px !important;
	transition: 0.2s;
	&:hover {
		background-color: darken($gold, 7%) !important;
	}
}
.slick-next.slick-arrow {
	.fa {opacity: 0 !important;}
	background: $gold url('../images/arrow-right.png') no-repeat !important;
	background-position: 8px 7px !important;
	transition: 0.2s;
	&:hover {
		background-color: darken($gold, 7%) !important;
	}
}
.wpcp-carousel-section.nav-vertical-center .slick-prev {
	left: 46.5%;
    text-align: center;
    margin-top: 70px !important;
    @media (max-width: 1199px) {
		left: 46%;
	}
	@media (max-width: 767px) {
		left: 44%;
	}
	@media (max-width: 650px) {
		left: 42%;
	}
	@media (max-width: 450px) {
		left: 40%;
	}
	@media (max-width: 350px) {
		left: 38%;
	}
}
.wpcp-carousel-section.nav-vertical-center .slick-next {
	right: 46.5%;
    text-align: center;
    margin-top: 70px !important;
    @media (max-width: 1199px) {
		right: 46%;
	}
	@media (max-width: 850px) {
		right: 45%;
	}
	@media (max-width: 650px) {
		right: 42%;
	}
	@media (max-width: 450px) {
		right: 40%;
	}
	@media (max-width: 350px) {
		right: 38%;
	}
}
.wpcp-carousel-section.slick-initialized .slick-slide {
	background: $white;
	padding: 40px 10px;
}
.wpcp-carousel-section.slick-initialized .slick-slide {
	margin-right: 15px;
}
.slick-list {
	padding-top: 20px;
	padding-bottom: 20px;
}
.wpcp-carousel-section.slick-initialized .slick-slide {
	box-shadow: #e6e6e6 4px 0px 10px;
}
.wpcp-carousel-section .slick-next, .wpcp-carousel-section .slick-next i, .wpcp-carousel-section .slick-prev, .wpcp-carousel-section .slick-prev i {
	line-height: 25px;
}
.wpcp-carousel-section .slick-next, .wpcp-carousel-section .slick-prev {
	width: 32px;
	height: 26px;
}
.custom-carousel-with-arc {
	.wpcp-carousel-wrapper {
		&:before {
			content: '';
			width: 105px;
			height: 105px;
			position: absolute;
			top: -25px;
			left: -25px;
			background: $light-yellow;
			display: inline-block;
			z-index: 9;
			border-radius: 50%;
			@media (max-width: 991px) {
				width: 75px;
			    height: 75px;
			    position: absolute;
			    top: -10px;
			    left: -10px;
			}
		}
		&:after {
			content: '';
			width: 105px;
			height: 105px;
			position: absolute;
			bottom: 30px;
			right: -25px;
			background: $light-yellow;
			display: inline-block;
			z-index: 9;
			border-radius: 50%;
			@media (max-width: 991px) {
				width: 75px;
			    height: 75px;
			    position: absolute;
			    bottom: 60px;
			    right: -10px;
			}
		}
		.wpcp-carousel-section {
			z-index: 99;
		}
	}
	
}
/*-- Image Slider --*/ 

.full-width-left-bg-image-module .vc_column-inner {
	background-position: right center !important;
	@media (max-width: 767px) {
		padding: 100px 0px;
	}
}
.full-width-left-bg-image-module {
	text-align: center;
	overflow: hidden;
	&:before {
		content: '';
		width: 200px;
		height: 200px;
		position: absolute;
		top: -85px;
		left: -110px;
		background: rgba(204,0,50,0.5);
		display: inline-block;
		z-index: 9;
		border-radius: 50%;
	}
	&:after {
		content: '';
		width: 200px;
		height: 200px;
		position: absolute;
		bottom: -85px;
		right: -110px;
		background: rgba(204,0,50,0.5);
		display: inline-block;
		z-index: 9;
		border-radius: 50%;
	}
	h1 {
		text-transform: uppercase;
		padding: 0 6%;
		line-height: 50px;
		margin-top: auto;
		margin-bottom: 40px;
	}
	.wpb_text_column {
		margin-bottom: auto;
	}
	.vc_column-inner {
		&:before {
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			bottom: 0;
			left: 0;
			background: url("../images/blue-trans-bg.png") repeat;
			display: inline-block;
			z-index: 9;
		}
		.wpb_wrapper {
			z-index: 9;
			display: flex;
		    align-items: center;
		    height: 100%;
		    flex-wrap: wrap;
		    justify-content: center;
		    .gold-button-with-arrow {
		    	text-transform: uppercase;
			    font-weight: 600;
			    font-size: 15px;
		    }
		}
	}
	
}
.full-width-right-module {
	display: flex;
    align-items: center;
}
.full-width-right-module .vc_column-inner {
	padding: 7% 18% 5% 8% !important;
	@media (max-width: 767px) {
		padding: 35px 15px 15px !important;
	}
}
.full-width-list-content{
	h2 {
		color: $black;
	}
	.icon-list-content {
		position: relative;
	    display: flex;
	    .list-left-img {
	    	width: 55px;
	    	position: relative;
		}
	}
	.list-right-txt {
		width: calc(100% - 55px);
    	margin-left: 25px;
    	h5 {
    		margin: 0px 0px 7px;
    	}
	}
}
.list-btn-section {
	margin-top: 35px;
}
.featured-content {
	padding-bottom: 90px !important;
	.vc_inner {
		&:before {
			content: '';
			width: 112px;
			height: 112px;
			position: absolute;
			top: 75px;
			left: -50px;
			background: url("../images/img-dots.png") no-repeat;
			display: inline-block;
			z-index: 1;
			@media (max-width: 1100px) {
				width: 75px;
    			height: 75px;
    			top: 95px;
    			left: -20px;
    			background-size: 75px;
			}
		}
		&:after {
			content: '';
			width: 112px;
			height: 112px;
			position: absolute;
			bottom: -40px;
			right: -38px;
			background: url("../images/img-dots.png") no-repeat;
			display: inline-block;
			z-index: 1;
			@media (max-width: 1100px) {
				width: 75px;
    			height: 75px;
    			bottom: -23px;
				right: 0px;
    			background-size: 75px;
			}
		}
	}
}
.top-image-card {
	height: 100%;
	width: 100%;
	padding:0;
	margin:12px 0;
	background: $white;
	box-shadow: #e6e6e6 0px 0px 25px;
    position: relative;
	z-index: 9;
	@media (max-width: 767px){
		padding-bottom: 30px;
		margin:0 0 30px 0;
	}

	@media (min-width: 768px) and (max-width: 1023px) {
		padding-bottom: 30px;
	}
	a{
		text-decoration: none;
	}
	.top-module-content{ 
		padding:35px 30px 25px 30px; 
	}
	.module-image-wrap {
		margin: 0;
		height: 180px;
		overflow: hidden;
		img {
			width: 100%;
			min-height: 180px;
			object-fit: cover;
		}
	}
	h4 {
		margin-bottom: 20px;
	}
	.module-card {
		padding:0;
		.module-text{
			margin-bottom: 30px;
		}
		/*
		.link-with-right-arrow{ margin-top: 20px; display: table;}*/
	}

	@media (min-width: 1024px) and (max-width: 1170px) {
		.module-image-wrap {
			height: auto !important;
			img {
				min-height: inherit !important;
			}
		}
	}

	@media (min-width: 768px) and (max-width: 1023px) {
		.module-image-wrap {
			height: auto !important;
			img {
				min-height: inherit !important;
			}
		}
	}

	@media (max-width: 767px) {
		.module-image-wrap {
			height: auto;
			img {
				min-height: inherit !important;
			}
		}
	}
} 
.seperator-heading.vc_sep_width_40 {
	text-align: center;
	@media (max-width: 1199px) {
		width: 45%;
	}
	@media (max-width: 767px) {
		width: 60%;
	}
	@media (max-width: 580px) {
		width: 80%;
	}
	@media (max-width: 767px) {
		h4 {
			font-size: 28px;
		}
	}
}