.site-header{
	position: fixed; 
	top: 0; 
	z-index: 9999; 
	width: 100%; 
	background: $white;
	transition: 0.2s;
	@media (max-width: 1199px) {
		box-shadow: #d4d4d4 0px 0px 6px;
	}
	@media (max-width: 992px) {
		position: relative;
	}
}
.site-header + * {
	margin-top: 230px;
	@media (max-width: 1199px) {
		margin-top: 176px;
	}
	@media (max-width: 992px) {
		margin-top: 0px;
	}
}
.site-header.shrink {
	.header-logo-section {
		padding: 10px 0px;
	}
	.header-logo img {
		max-width: 200px;
	} 
	.header-btn {
		margin-bottom: 7px;
	}
}
header {	 
	.header-logo-section {
		background: $white;
		padding: 25px 0px;
		transition: 0.2s;
		.header-logo {
			img {
				max-width: 305px;
				height: auto;
				transition: 0.2s;
				@media (max-width: 767px) {
					max-width: 245px;
				}
			}
			@media (max-width: 767px) {
				margin: 0 auto 25px;
    			display: table;
			}
		}
	}
	.header-btn {
		display: flex;
	    justify-content: flex-end;
	    flex-wrap: wrap;
	    margin-bottom: 27px;
	    @media (max-width: 767px) {
	    	justify-content: center !important;
	    }
		a {
			text-transform: uppercase;
			font-size: 14px;
			font-weight: 600;
			margin-left: 10px;
		}
		.blue-button-with-arrow:before {
			font-weight: 600;
		}
	}
	.header-nav {
		background: $blue;
	}
	.header-search-section {
		.header-search {
			max-width: 270px;
			@media (max-width: 1199px) and (min-width: 768px) {
				margin-right: 55px;
			}
			@media (max-width: 400px) {
				max-width: 225px;
			}
		}
		display: flex;
	    justify-content: flex-end;
	    flex-wrap: wrap;
	    align-items: center;
	    @media (max-width: 767px) {
	    	justify-content: center;
	    }
	    @media (max-width: 480px) {
	    	justify-content: left;
	    }
	    a {
	    	margin-right: 20px;
	    	@media (max-width: 400px) {
	    		margin-right: 10px;
	    	}
	    }
	    .search-field {
		    background: #fff;
		    width: 100%;
		    float: left;
		    border-radius: 50px;
		    border: 2px solid $gray-light;
		    padding: 3px 50px 3px 25px;
		    line-height: 30px;
		    font-size: 14px;
		    @media (max-width: 400px) {
				padding: 3px 45px 3px 15px;
			}
		}
		.header_right_form button {
		    border: none;
		    color: $gray-light;
		    background: none;
		    padding: 0;
		    float: right;
		    margin: -40px 15px 0 0;
		    font-size: 23px;
		    cursor: pointer;
		    .fa-search {
		    	width: 35px;
		    	height: 40px;
    			line-height: 40px;
		    }
		}
	}
}
@media (max-width: 1199px) {
	.site-header.shrink {
		.mega-menu-toggle {
			top: -50px;
		}
	}
}
.main-navigation {
	#mega-menu-wrap-primary .mega-menu-toggle .mega-toggle-block-1:after {
		font-size: 45px;
	}
	@media (max-width: 1199px) {
		ul.mega-sub-menu {
			li.mega-menu-column {
				/*padding: 0px 30px !important;*/
				&:first-child {
					border-top: $black 1px solid !important;
					padding: 10px 50px 10px 0px !important;
				}
			}
		}
		.mega-menu-toggle {
			background: transparent !important;
			position: absolute;
			top: -80px;
			right: -5px;
		}
	}
	@media (max-width: 767px) {
		.mega-menu-toggle {
			top: -65px;
		}
	}
	@media (max-width: 575px) {
		.mega-menu-toggle {
			background: transparent !important;
			position: absolute;
			top: -65px;
			right: -5px;
		}
	}
}


ul.max-mega-menu li:last-child a.mega-menu-link {padding-right: 0px !important;}
#mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-item {
	font-weight: 500;
}
li.mega-menu-item-has-children > a {font-weight: 600 !important;}
.site-header .main-navigation #mega-menu-wrap-primary #mega-menu-primary li.mega-menu-item-has-children>a.mega-menu-link>span.mega-indicator:after {
	font-weight: 600;
	transition: all ease .3s !important;
    -ms-transition: all ease .3s !important;
    -webkit-transition: all ease .3s !important;
}
.site-header .main-navigation .mega-toggle-on span.mega-indicator:after {
	transform: rotate(-180deg) !important;
    transition: all ease .3s !important;
    -ms-transition: all ease .3s !important;
    -webkit-transition: all ease .3s !important;
}
#mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-column > ul.mega-sub-menu > li.mega-menu-item > a.mega-menu-link:before {
	position: absolute;
	content: '';
	width: 10px;
	height: 2px;
	background: $blue;
	top: 11px;
	left: 5px;
}
#mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-column > ul.mega-sub-menu > li.mega-menu-item li.mega-menu-item > a.mega-menu-link:hover {
	text-decoration: underline;
}
#mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-column > ul.mega-sub-menu > li.mega-menu-item li.mega-menu-item > a.mega-menu-link:before {
	position: absolute;
	content: '';
	width: 4px;
	height: 1px;
	background: $blue;
	top: 11px;
	left: 21px;
}
#mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-column > ul.mega-sub-menu > li.mega-menu-item li.mega-menu-item:hover > a.mega-menu-link:before {
	background: $black;
}
.mega-menu-item {
	p {
		font-size: 14px;
		font-weight: 400;
	}
}

/*-- Left two backgroung color options --*/
.two-color-bg.blue ul.mega-sub-menu li.mega-menu-column:first-child {
	background: $blue !important;
	.right-arrow {
		a.mega-menu-link {
			color: $white !important;
			&:hover {
				color: $black !important;
				text-decoration: none !important;
				&:before {
					background: $black !important;
					color: $white !important;
				}
			}
			&:before {
				font-family: dashicons !important;
		    	content: "\f345" !important;
		    	background: $white !important;
				color: $black !important;
			}
		}
	}
}
.two-color-bg.old-gold ul.mega-sub-menu li.mega-menu-column:first-child {
	background: $gold !important;
	.right-arrow {
		a.mega-menu-link {
			color: $white !important;
			&:hover {
				color: $black !important;
				text-decoration: none !important;
				&:before {
					background: $black !important;
					color: $white !important;
				}
			}
			&:before {
				font-family: dashicons !important;
		    	content: "\f345" !important;
		    	background: $white !important;
				color: $gold !important;
			}
		}
	}
}
.two-color-bg.gray ul.mega-sub-menu li.mega-menu-column:first-child {
	background: $gray !important;
}
.two-color-bg.black ul.mega-sub-menu li.mega-menu-column:first-child {
	background: $black !important;
	.right-arrow {
		a.mega-menu-link {
			color: $white !important;
			&:hover {
				color: $gold !important;
				text-decoration: none !important;
				&:before {
					background: $gold !important;
					color: $black !important;
				}
			}
			&:before {
				font-family: dashicons !important;
		    	content: "\f345" !important;
		    	background: $white !important;
				color: $black !important;
			}
		}
	}
}
.two-color-bg.venetian-red ul.mega-sub-menu li.mega-menu-column:first-child {
	background: $red !important;
	.right-arrow {
		a.mega-menu-link {
			color: $white !important;
			&:hover {
				color: $black !important;
				text-decoration: none !important;
				&:before {
					background: $black !important;
					color: $white !important;
				}
			}
			&:before {
				font-family: dashicons !important;
		    	content: "\f345" !important;
		    	background: $white !important;
				color: $red !important;
			}
		}
	}
}
.two-color-bg.light-yellow ul.mega-sub-menu li.mega-menu-column:first-child {
	background: $light-yellow !important;
}
.two-color-bg.mahogany ul.mega-sub-menu li.mega-menu-column:first-child {
	background: $brown !important;
	.right-arrow {
		a.mega-menu-link {
			color: $white !important;
			&:hover {
				color: $black !important;
				text-decoration: none !important;
				&:before {
					background: $black !important;
					color: $white !important;
				}
			}
			&:before {
				font-family: dashicons !important;
		    	content: "\f345" !important;
		    	background: $white !important;
				color: $brown !important;
			}
		}
	}
}
.two-color-bg.silver ul.mega-sub-menu li.mega-menu-column:first-child {
	background: $gray-dark !important;
}
/*-- Left two backgroung color options --*/

.two-color-bg ul.mega-sub-menu li.mega-menu-column:first-child {
	background: $sky-blue !important;
	padding-left: 20px !important;
	padding-right: 20px !important;
	@media (min-width: 1199px) {
		width: 20% !important;
		margin-right: 4% !important;
	}
	a.mega-menu-link {
		float: left !important;
	    width: auto !important;
		padding: 0px 25px 0px 0px !important;
		&:before {content: none !important;}
	}
	.right-arrow {
		/*padding: 5px 0px 5px 0px !important;*/
		a.mega-menu-link {
			&:hover {
				color: $black !important;
				text-decoration: none !important;
				&:before {background: $black !important;}
			}
			&:before {
				font-family: dashicons !important;
		    	content: "\f345" !important;
		    	height: 12px !important;
		    	width: 12px !important;
		    	top: 5px !important;
		    	right: 0px !important;
		    	left: auto !important;
		    	background: $blue !important;
				color: $white !important;
				border-radius: 50px;
		    	font-size: 9px !important;
		    	padding: 0px 0px 0px 2px;
	    		line-height: 14px !important;
			}
		}
	}
}
li.mega-disable-link {
	padding: 0px !important;
}
li.mega-disable-link a.mega-menu-link:before {
	content: none !important;
}
@media only screen and (min-width: 1199px) {
	#mega-menu-wrap-primary #mega-menu-primary li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-row > ul.mega-sub-menu > li.mega-menu-columns-3-of-12 {
		padding: 35px 0px 30px;
	}
	.two-color-bg ul.mega-sub-menu ul.mega-sub-menu {
		display: flex !important;
		flex-wrap: wrap;
	}
	.two-color-bg ul.mega-sub-menu ul.mega-sub-menu ul.mega-sub-menu {
		display: block !important;
	}
}
@media only screen and (max-width: 1199px) {
	#mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-megamenu > ul.mega-sub-menu {
		padding: 0px;
	}
	#mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-item > a.mega-menu-link {
		padding: 0 10px !important;
	}
}