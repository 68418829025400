.site-footer {
	padding: 60px 0px 20px;
	background-color: $gray-light;
	.address-col-section {
		padding-right: 0px !important;
		.address-col {
			p {
				font-size: 15px;
			}
		}
	}
	.footer-top-links {
		.link-col {
			margin-bottom: 25px;
			ul {
				li {
					line-height: 20px;
				}
			}
		}
		.link-title {
			margin-bottom: 15px;
			strong {line-height: 20px;}
			a {
				color: $blue;
				cursor: default;
				&:hover {
					cursor: default;
					text-decoration: none !important;
				}
			}
			@media (max-width: 767px) {
				margin-bottom: 7px;
			}
		}
		padding-bottom: 25px;
		.footer-logo {
			display: block;
			margin-bottom: 25px;
			img {
				max-width: 270px;
				width: 100%;
			}
		}
		.footer-btn {
			margin-top: 5px;
			li {
				width: 100%;
    			display: inline-block;
				a {
					padding: 7px 30px 7px 15px;
					text-transform: uppercase;
					font-size: 15px;
					font-weight: 600;
					width: 140px; 
					margin-bottom: 10px;
					text-align: center;
					&:before {
						padding-left: 2px;
						text-align: center;
					}
					@media (max-width: 992px) and (min-width: 768px) {
						width: 120px;
						padding: 7px 26px 7px 15px;
						font-size: 12px;
						
					}
				}
				@media (max-width: 992px) and (min-width: 768px) {
					.gold-button-with-arrow:before {
						right: 15px;
					}
				}
				@media (min-width: 768px) {
					text-align: right;
				}
			}
		}
	}	
	.copyright-section {
		padding-top: 20px;
		border-top: #829ec1 2px solid;
		@media (max-width: 767px) {
			padding-top: 25px;
			text-align: center;
		}
		p {
			color: $blue;
		}
		.privacy-link {
			span {padding: 0px 5px;}
			a {
				color: $blue;
				text-decoration: none;
				&:hover {color: $black;}
			}
			@media (max-width: 767px) {
				margin: 0px 0px 15px;
			}
		}
		.social-right-section {
			p {
				text-align: right;
				@media (max-width: 767px) {
					text-align: center;
				}
			}
			.footer-social-links {
				.fa {
					color: $white; 
					width: 30px; 
					height: 30px; 
					line-height: 31px;
					text-align: center;
					border-radius: 50%;
					margin-left: 0px;
				}
				.fa-facebook {
					transition: 0.2s;
					background: $brand-facebook;
					&:hover {background: darken($brand-facebook, 10%);}
				}
				.fa-twitter {
					transition: 0.2s;
					background: $brand-twitter;
					&:hover {background: darken($brand-twitter, 15%);}
				}
				.fa-linkedin {
					transition: 0.2s;
					background: $brand-linkedin;
					&:hover {background: darken($brand-linkedin, 10%);}
				}
				.fa-youtube {
					transition: 0.2s;
					background: $brand-youtube;
					&:hover {background: darken($brand-youtube, 10%);}
				}
			}
		}
	}
}

