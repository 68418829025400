
// Start with assigning color names to specific hex values.
$white:  #ffffff !default;
$black:  #000000 !default;
$red:    #cc0032 !default;
$orange: #eaa43d !default;
$gold: 	 #d5b700 !default;
$green:  #5cb85c !default;

$blue:   	   #0b467a !default;
$pink:		   #cc0031 !default;
$gray:		   #e5e5e5 !default;
$light-yellow: #fffce6 !default;
$brown: 	   #bf390b !default;
$sky-blue:	   #e2e7ff !default;

$site-color:   #000000 !default; 
$hr-color: #dbd7d1 !default;

// Create grayscale
$gray-dark:                 #bdbdbd !default;
$gray-light:                #efefef !default;
$gray-lighter:              #eceeef !default;
$gray-lightest:             #f1f1f1 !default;

// Reassign color vars to semantic color scheme
$brand-primary:             $site-color !default;
$brand-success:             $green !default;
$brand-info:                $blue !default;
$brand-warning:             $orange !default;
$brand-danger:              $red !default;
$brand-inverse:             $gray-dark !default;

$brand-facebook:            #3b5999 !default;
$brand-twitter:             #55acee !default;
$brand-linkedin:            #007ab9 !default;
$brand-podcast:             #20a4b0 !default;
$brand-youtube:             $red !default;
$brand-instagram:           #6a453b !default;

// Z-Index

$z-index-base: 1;
$z-index-mid: 2;
$z-index-high: 3;
$z-index-uber: 4;


// Design sizes

$lgdesktop-design: 1440px;
$smdesktop-design: 1024px;
$tablet-design: 768px;
$mobile-design: 320px;


// Breakpoints

$lgdesktop-breakpoint: 1440px;
$smdesktop-breakpoint: 1200px;
$tablet-breakpoint: 1023px;
$smtablet-breakpoint: 750px;
$mobile-breakpoint: 600px;


// Media Queries

$lgdesktop-query: 'only screen and (max-width: ' + $lgdesktop-breakpoint + ')';
$smdesktop-query: 'only screen and (max-width: ' + $smdesktop-breakpoint + ')';
$tablet-query: 'only screen and (max-width: ' + $tablet-breakpoint + ')';
$smtablet-query: 'only screen and (max-width: ' + $smtablet-breakpoint + ')';
$mobile-query: 'only screen and (max-width: ' + $mobile-breakpoint + ')';


// Colors



// Fonts

// $header-font: "Custom Font", sans-serif;
// $body-font: "Custom Font", sans-serif;

// Default wrapper sizing (flex and grid)
$wrapper-width: 1576px;