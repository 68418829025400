.content-page-banner {
	padding: 70px 0px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-color: darken($gray-dark, 20%);
	.header-content {
		text-align: center;
		h2 {
			color: $white;
	    	margin-bottom: 20px;
		}
		p {
			color: $white;
		}
	}
}
.page-breadcrumbs {
	background: $white;
	border-bottom: $sky-blue 2px solid;
	padding: 12px 0px;
	margin-bottom: 25px;
	a {
		text-decoration: none;
		color: $blue;
		font-size: 14px;
		&:hover {color: $black;}
		&.active {
			color: $black;
			pointer-events: none;
		}
	}
	span {
		padding: 0px 10px;
		color: $blue;
		.fa {
			font-size: 14px;
			font-weight: 200;
		}
	}
}
.sidebar-link-section {
	padding: 15px 0px 30px;
	p {
		margin: 0px 0px 7px;
		font-size: 18px;
	}
	h3 {
		margin: 0px 0px 15px;
	}
	.right-sidebar {
		padding: 0px 0px 0px 25px;
		border-left: $blue 1px solid;
		@media (max-width: 767px) {
			padding: 4px 0px 5px 0px;
			border-left: $blue 0px solid;
		}
		ul {
			list-style: none;
			margin-left: 0px;
			margin-bottom: 0px;
			line-height: 25px;
		}
		.sidebar-head {
			font-size: 17px;
			color: $black;
			span {
				font-weight: 600;
				display: block;
    			margin: 0px 0px 7px;
			}
		}
		ul {
			li {
				a {
					color: $blue;
					text-decoration: none;
					&:hover {
						color: $black;
					}
				}
			}
		}
	}
}
.chapter-details {
	padding: 0px 0px 30px;
	h1 {
		margin: 0px 0px 20px;
	}
	.header-banner {
		display: flex;
		align-items: center;
		background: $gray;
		margin: 0px 0px 30px;
		@media (max-width: 767px){
			display: block;
		}
	}
	.ch-img {
		width: 50%;
		@media (max-width: 767px){
			width: 100%;
			text-align: center;
			img {width: 100%;}
		}
	}
	.ch-social {
		width: 50%;
		background: $gray;
		padding: 20px 20px;
		text-align: center;
		@media (max-width: 767px){
			width: 100%;
		}
		ul {
			display: flex;
		    align-items: center;
		    justify-content: center;
		    li {
				display: inline-block;
				margin: 0px 5px 0px;
				transition: 0.4s;
				a {
					text-decoration: none;
				}
				a.iasaci-social {
					background: $brand-twitter;
					font-size: 20px;
					font-family: 'ArialNarrow';
					border-radius: 25px;
					color: $white;
					padding: 6px 20px;
					@media (max-width: 767px){
						padding: 6px 15px;
					}
				}
				&:last-child {
					margin: 0px 0px 0px 20px;
				}
				&:hover {
					opacity: 0.7;
				}
			}
			li:last-child:before {
				padding: 8px 10px;
				content: "";
				color: darken($gray, 10%);
				border-left: darken($gray, 10%) 2px solid;
				position: relative;
				top: -13px;
			}
		}
		img {
			height: 42px;
		}
	}
	h2.chp-heading {
		&:after {
			content: '';
			position: absolute;
			height: 2px;
			width: 80px;
			background: $brown;
			margin-left: 20px;
			top: 21px;
			@media (max-width: 767px) {
		    	width: 40px;
		    }
		}
	}
	.chapter-events-detail {
		position: relative;
		margin-bottom: 50px;
		h4 {
			color: $gold;
		}
		.strong-txt {
			margin-bottom: 15px;
			font-weight: 600;
		}
	}
	.chapter-officers {
		position: relative;
		.ms-icon {
			position: absolute;
			top: 5px;
			right: 0px;
			@media (max-width: 480px) {
				position: relative;
				top: -20px;
				text-align: right;
			}
		}
	}
	.upcoming-chapter-events {
		margin-bottom: 50px;
		background: $gray;
		padding: 45px 0px 35px;
		.chapter-carousel {
			position: relative;
			h2 {
				text-transform: uppercase;
				text-align: center;
				color: $black;
				@media (max-width: 600px) {
					margin: 0 auto 0px;
    				width: 60%;
    				display: flex;
    				align-items: center;
    				position: relative;
    				left: 40px;
				}
				&:before {
				    content: '';
				    position: relative;
				    display: inline-block;
				    height: 2px;
				    width: 80px;
				    background: #bf390b;
				    top: -9px;
				    left: -12px;
				    margin-left: -80px;
				    @media (max-width: 767px) {
				    	width: 40px;
				    }
				    @media (max-width: 600px) {
				    	top: 0px;
				    }
				}
				&:after {
				    content: '';
				    position: absolute;
				    height: 2px;
				    width: 80px;
				    background: #bf390b;
				    margin-left: 20px;
				    top: 21px;
				    @media (max-width: 767px) {
				    	width: 40px;
				    }
				    @media (max-width: 600px) {
				    	display: inline-block;
				    	position: relative;
				    	margin-left: 0px;
				    	top: 0px;
				    	right: -10px;
				    }
				}
			}
			.owl-carousel {
				@media (max-width: 600px) {
					margin-top: 25px;
				}
			}
			.owl-item {
				.item {
					display: flex;
					background: #fff;
    				padding: 15px 15px 20px;
				}
				img {
					display: block;
				    height: 55px;
				    width: auto;
				    margin: 0px 20px 0px 0px;
				}
				.events-info {
					h5 {
						margin: 0px 0px 5px;
					}
					p {
						margin: 0px 0px 15px;
					}
				}
			}
			.owl-prev {
				.fa {opacity: 0 !important;}
				padding: 5px 14px !important;
				border-radius: 0;
				background: $gold url('../images/arrow-left.png') no-repeat !important;
				background-position: 8px 7px !important;
				transition: 0.2s;
				&:hover {
					background-color: darken($gold, 7%) !important;
				}
			}
			.owl-next {
				.fa {opacity: 0 !important;}
				padding: 5px 14px !important;
				border-radius: 0;
				background: $gold url('../images/arrow-right.png') no-repeat !important;
				background-position: 8px 7px !important;
				transition: 0.2s;
				&:hover {
					background-color: darken($gold, 7%) !important;
				}
			}
		}
	}
}

.event-listing {
	padding: 35px 0px 50px;
	ul {
		margin-left: 0px;
	}
	.list-box {
		display: flex;
		background: $gray-lightest;
		/*box-shadow: 0px 0px 20px 5px $light-yellow;*/
		padding: 30px;
		margin: 0px 0px 30px;
		/*&:last-child {
			margin: 0px 0px 0px;
		}*/
		a {
			text-decoration: none;
			h4:hover {
				text-decoration: underline;
			}
		}
		.main-img {
			padding: 0px;
			max-width: 250px;
			margin-right: 35px;
			display: flex;
			align-items: center;
			@media (max-width: 767px) {
				margin-bottom: 25px;
			}
		}
		.listing-right-content {
			width: calc(100% - 250px);
			padding: 0px;
			h4 {
				margin-bottom: 15px;
				display: inline-block;
			}
			.lising-date, .lising-title, .lising-company, .lising-pr_author {
				margin-bottom: 15px;
			}
			.listing-link {
				margin: 15px 0px 0px;
			}
		}
		@media (max-width: 767px) {
			.listing-right-content {
				width: 100%;
			}
			display: block !important;
		}
	}
}
.pagination {
	text-align: center;
	margin-top: 50px !important;
	padding-bottom: 50px;
	ul {
		border-radius: 0px !important;
		li {
			display: inline-block;
			a {
				&:hover {
					text-decoration: none;
				}
			}
			.page-numbers {
				position: relative;
				background: $gray;
				color: lighten($black, 20%);
				padding: 12px 18px;
				margin: 0px 8px;
				width: 40px;
				text-align: center;
				border-radius: 0px !important;
				border: 0px;
				transition: 0.2s ease all;
				&:hover {
					background: $blue;
					color: $white;
				}
			}
			.page-numbers.current {
				background: $blue;
				color: $white;
			}
			.next.page-numbers {
				&:before {
					content: "\f061";
					position: absolute;
					font-family: FontAwesome;
					font-weight: 300;
					font-size: 14px;
					left: 15px;
				}
			}
			.prev.page-numbers {
				&:before {
					content: "\f060";
					position: absolute;
					font-family: FontAwesome;
					font-weight: 300;
					font-size: 14px;
					right: 15px;
				}
			}
		}
	}
}

/*-- Search Page --*/
.search-banner-section {
    width: 100%;
    position: relative;
    display: inline-block;
    overflow: hidden;
    min-height: 300px;
    background-color: $gold;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    display: flex;
    align-items: center;
        .heading-content {
            text-align: center;
            padding: 0px 0px 15px;
        }
        
        h1 {
            padding-right: 50px;
            margin: 0 auto;
            color: $white;
            @media (max-width: 991px) {
                font-size: 40px;
            }
            @media (max-width: 767px) {
                font-size: 30px;
                text-align: center;
            }
            @media (max-width: 520px) {
                font-size: 25px;
                line-height: 30px;
            }
        }
        @media (max-width: 767px) {
            align-items: center;
            text-align: center;
        }
        @media (max-width: 520px) {
            min-height: 370px;
        }

    @media (max-width: 520px) {
        min-height: 370px;
    }   
}

.search-col {
	background: $gray-lightest;
	margin: 0px auto 40px;
    padding: 30px 30px;
    position: relative;
    float: left;
    min-height: 205px;
    width: 100%;
    .link {
    	margin: 0px !important;
    }
}

/*-- Single Events --*/
.single-event-page {
	padding: 30px 0px 30px; 
	h2 {margin-bottom: 10px;}
	img.wp-post-image {display: none;}
	.event-date-col {
		margin-bottom: 20px;
		font-style: italic;
		color: $red;
		font-weight: 600;
	}
}

/*-- Committee listing --*/
.committee-listing-section {
	.right-logo-img {
		background-color: $gray-light;
		text-align: center;
		padding: 15px 20px 40px;
	}
}
.committee-quick-links {
	h5 {
		text-align: center;
		margin-bottom: 20px;
	}
	ul {
		position: relative;
		list-style: none;
    	margin-left: 0px;
    	display: flex;
    	flex-wrap: wrap;
    	justify-content: center;
		li {
			position: relative;
		    width: 15%;
		    float: left;
		    text-align: center;
		    background-color: $gray-light;
		    min-height: 95px;
		    margin: 0px 15px 15px 0px;
		    padding: 10px 10px;
		    border-radius: 5px;
		    display: inline-flex;
		    align-items: center;
		    justify-content: center;
		    &:hover {
		    	background-color: $light-yellow;
		    	border: 1px solid darken($light-yellow, 20%);
		    }
		    @media (max-width: 767px) {
		    	width: 30%;
		    }
		    @media (max-width: 480px) {
		    	width: 42%;
		    }
			a {
				position: relative;
				height: 100%;
			    display: flex;
			    align-items: center;
			}
		}
	}
}