
@mixin clearfix {
	*zoom: 1;
	&:before, &:after { display: table; content: ""; }
	&:after { clear: both; }
}

@mixin aspect-ratio($ratio-or-width, $height: null) {
    $padding: if($height, percentage($height/$ratio-or-width), percentage(1/$ratio-or-width));
    &:before { content:''; display: table-row; float: left; padding-bottom: $padding;  }
    &:after  { content:''; display: table; clear: both;  } 
}

@mixin placeholder($color, $fontsize, $lineheight){
	&::-webkit-input-placeholder { color: $color; font-size: $fontsize; line-height: $lineheight; text-transform: uppercase }
	&:-moz-placeholder { color: $color; font-size: $fontsize; line-height: $lineheight; text-transform: uppercase }
	&::-moz-placeholder { color: $color; font-size: $fontsize; line-height: $lineheight; text-transform: uppercase }
	&:-ms-input-placeholder { color: $color; font-size: $fontsize; line-height: $lineheight; text-transform: uppercase }
}

@mixin center {
	position: absolute;
	top: 50%;
	left: 50%;
	@include transform( translate(-50%, -50%) );
}

@mixin centerX {
	position: absolute;
	left: 50%;
	@include transform( translateX(-50%) );
}

@mixin centerY {
	position: absolute;
	top: 50%;
	@include transform( translateY(-50%) );
}

@mixin responsive-font( $window, $font, $min: false, $max: false ) {

	$responsive-unitless: $font / ( $window / 100 );
	$responsive: $responsive-unitless + vw;

	@if $min {
		$min-breakpoint: $min / $responsive-unitless * 100;

		@media (max-width: #{$min-breakpoint}) {
			font-size: $min;
		}
	}

	@if $max {
		$max-breakpoint: $max / $responsive-unitless * 100;

		@media (min-width: #{$max-breakpoint}) {
			font-size: $max;
		}
	}

	font-size: $font;
	font-size: $responsive;
}

// Functions

@function rv( $window, $value  ) {

	$design-width: $window;

	@if $window == l {
		$design-width: $lgdesktop-design;
	} @else if $window == s {
		$design-width: $smdesktop-design;
	} @else if $window == t {
		$design-width: $tablet-design;
	} @else if $window == m {
		$design-width: $mobile-design;
	}

	$responsive-unitless: $value / ( $design-width / 100 );
	$responsive: $responsive-unitless + vw;
	@return $responsive;

}


// Utility classes

.hidden {
	display: none;
}

.clearfix {
	@include clearfix;
}

.float-right,
[class^="col-"].float-right, 
[class*=" col-"].float-right {
	float: right;
}

.float-left,
[class^="col-"].float-left, 
[class*=" col-"].float-left {
	float: left;
}

.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}

.text-left {
	text-align: left;
}

.mobile-only,
.tablet-only {
	display: none;
}

@media #{$tablet-query} {

	.desktop-only {
		display: none;
	}

	.tablet-only {
		display: block;
	}

}

@media #{$mobile-query} {

	.tablet-only,
	.mobile-hide {
		display: none;
	}

	.mobile-only {
		display: block;
	}

}