@font-face {
    font-family: 'ArialNarrow';
    src: url('../fonts/arialnb-webfont.eot');
    src: url('../fonts/arialnb-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/arialnb-webfont.woff2') format('woff2'),
         url('../fonts/arialnb-webfont.woff') format('woff'),
         url('../fonts/arialnb-webfont.ttf') format('truetype'),
         url('../fonts/arialnb-webfont.svg') format('svg');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'ArialNarrowRegular';
    src: url('../fonts/arialn-webfont.eot');
    src: url('../fonts/arialn-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/arialn-webfont.woff2') format('woff2'),
         url('../fonts/arialn-webfont.woff') format('woff'),
         url('../fonts/arialn-webfont.ttf') format('truetype'),
         url('../fonts/arialn-webfont.svg') format('svg');
    font-weight: normal;
    font-style: normal;
    }

/*font-family: 'Arial', sans-serif;*/
/*font-family: 'ArialNarrow', sans-serif;*/
/*font-family: 'ArialNarrowRegular';*/

*,
body,
html {
	font-family:'Arial', sans-serif;
}

html {
	height: 100%;
}

body {
	min-height: 100%;
	background: $white;
	color: $black;
}

p {
	line-height: 24px; 
	margin: 0px 0px 25px; 
	font-family: 'Arial', sans-serif;
	font-size: 16px;
	color: $black;
}
a {
	color: $blue; 
	text-decoration: none;
	transition: 0.2s ease all;
	&:hover {
		color: $black; 
		text-decoration: underline;
	}
}
sup {
	font-size: 11px !important;
	position: relative;
	top: -7px;
}
sub {
	font-size: 11px !important;
	position: relative;
	bottom: -7px;
}


// Typography

h1 {
	font-family: 'ArialNarrow', sans-serif;
	font-weight: 600;
	font-size: 40px;
	color: $blue;
	margin: 0px 0px 30px;
	line-height: 60px;
}
h2 {
	font-family: 'ArialNarrow', sans-serif;
	font-weight: 600;
	font-size: 32px;
	color: $blue;
	margin: 0px 0px 25px;
	line-height: 40px;
	position: relative;
}
h3 {
	font-family: 'ArialNarrow', sans-serif;
	font-weight: 600;
	font-size: 28px;
	color: $blue;
	margin: 0px 0px 25px;
	line-height: 1.2em;
}
h4 {
	font-family: 'ArialNarrow', sans-serif;
	font-weight: 600;
	font-size: 24px;
	color: $blue;
	margin: 0px 0px 20px;
	line-height: 1.2em;
}
h5 {
	font-family: 'ArialNarrow', sans-serif;
	font-weight: 600;
	font-size: 20px;
	color: $blue;
	margin: 0px 0px 10px;
}
h6 {
	font-family: 'ArialNarrow', sans-serif;
	font-weight: 600;
	font-size: 16px;
	color: $blue;
	margin: 0px 0px 15px;
}
.hide {
	display: none !important;
}
.link-with-right-arrow {
	position: relative;
	color: $blue;
	font-size: 16px;
	font-weight: 500;
	transition: 0.1s ease all;
	padding: 0px;
	cursor: pointer;
	text-decoration: none;
	&:hover {
		color: $black;
		text-decoration: none;
    	border-radius: 0;
    	&:before {color: $white; background: $black;}
	}
	&:before {
		font-family: "FontAwesome";
		content: "\f054";
		position: absolute;
		color: $white;
		right: -20px;
		top: 1px;
		font-size: 9px;
		font-weight: 500;
		transition: 0.1s ease all;
		background: $blue;
		border-radius: 10px;
		height: 14px;
    	width: 14px;
    	line-height: 1;
    	padding-left: 4px;
    	padding-top: 3px;
	}
}
.link-with-down-arrow {
	position: relative;
	color: $blue;
	font-size: 16px;
	font-weight: 500;
	transition: 0.2s ease all;
	padding: 0px 22px 0px 0px;
	cursor: pointer;
	text-decoration: none;
	&:hover {
		color: $black;
		text-decoration: none;
    	border-radius: 0;
    	&:after {color: $black; content: "\f078";}
	}
	&:after {
		font-family: FontAwesome;
		content: "\f078";
		position: absolute;
		color: $blue;
		right: 3px;
		top: -3px;
		font-size: 12px;
		font-weight: 500;
		transition: 0.1s ease all;
	}
}
.white-button-with-arrow {
	padding: 7px 40px 7px 25px;
	display: inline-block;
	position: relative;
	font-size: 16px;
	color: $blue;
	background: $white;
	border: $blue 2px solid;
	border-radius: 50px;
	cursor: pointer;
	text-decoration: none;
	font-weight: 500;
	&:hover {
		color: $white;
		background: $blue;
		border: $blue 2px solid;
		text-decoration: none;
		&:before {color: $blue; background: $white;}
	}
	&:before {
		font-family: "FontAwesome";
		content: "\f054";
		position: absolute;
		color: $white;
		right: 20px;
		top: 11px;
		font-size: 9px;
		font-weight: 500;
		transition: 0.1s ease all;
		background: $blue;
		border-radius: 10px;
		height: 14px;
    	width: 14px;
    	line-height: 1;
    	padding-left: 4px;
    	padding-top: 3px;
	}
}
.blue-button-with-arrow {
	padding: 7px 40px 7px 25px;
	display: inline-block;
	position: relative;
	font-size: 16px;
	color: $white;
	background: $blue;
	border: $blue 1px solid;
	border-radius: 50px;
	cursor: pointer;
	text-decoration: none;
	font-weight: 500;
	&:hover {
		color: $white;
		background: $black;
		border: $black 1px solid;
		text-decoration: none;
		&:before {color: $black; background: $white;}
	}
	&:before {
		font-family: "FontAwesome";
		content: "\f054";
		position: absolute;
		color: $blue;
		right: 20px;
		top: 11px;
		font-size: 9px;
		font-weight: 500;
		transition: 0.1s ease all;
		background: $white;
		border-radius: 10px;
		height: 14px;
    	width: 14px;
    	line-height: 1;
    	padding-left: 4px;
    	padding-top: 3px;
	}
}
.blue-button {
	padding: 7px 25px 7px 25px;
	display: inline-block;
	position: relative;
	font-size: 16px;
	color: $white;
	background: $blue;
	border: $blue 1px solid;
	border-radius: 50px;
	cursor: pointer;
	text-decoration: none;
	font-weight: 500;
	&:hover {
		color: $white;
		background: $black;
		border: $black 1px solid;
		text-decoration: none;
	}
	
}
.gold-button-with-arrow {
	padding: 7px 40px 7px 25px;
	margin: 0px 0px 10px;
	position: relative;
	font-size: 16px;
	color: $black;
	background: $gold;
	border: $gold 1px solid;
	border-radius: 50px;
	cursor: pointer;
	text-decoration: none;
	font-weight: 500;
	display: inline-block;
	&:hover {
		color: $white;
		background: $black;
		border: $black 1px solid;
		text-decoration: none;
		&:before {color: $black; background: $white;}
	}
	&:before {
		font-family: "FontAwesome";
		content: "\f054";
		position: absolute;
		color: $gold;
		right: 20px;
		top: 11px;
		font-size: 9px;
		font-weight: 500;
		transition: 0.1s ease all;
		background: $black;
		border-radius: 10px;
		height: 14px;
    	width: 14px;
    	line-height: 1;
    	padding-left: 4px;
    	padding-top: 3px;
	}
}
.gold-button {
	padding: 7px 25px 7px 25px;
	margin: 0px 0px 10px;
	position: relative;
	font-size: 16px;
	color: $black;
	background: $gold;
	border: $gold 1px solid;
	border-radius: 50px;
	cursor: pointer;
	text-decoration: none;
	font-weight: 500;
	display: inline-block;
	&:hover {
		color: $white;
		background: $black;
		border: $black 1px solid;
		text-decoration: none;
	}
}
.hide {
	display: none !important;
}
.text-align-right {
	text-align: right;
}
.wpb_wrapper, .main-content {
	ul {
		list-style: disc;
		margin-bottom: 20px;
		margin-left: 20px;
		ul {
			margin-bottom: 5px;
		}
	}
}
.wpb_wrapper, .main-content {
	ol {
		margin-bottom: 20px;
		margin-left: 20px;
	}
}

/*-- Custom Tab --*/
.vc_tta.vc_tta-shape-rounded .vc_tta-tab>a {
    border-radius: 0 !important;
    padding: 14px 30px !important;
}
.vc_tta-color-grey.vc_tta-style-classic .vc_tta-tab>a {
    border-color: darken($gray, 10%) !important;
    background: $white !important;
    color: $blue !important;
    text-transform: uppercase;
    font-family: 'ArialNarrow';
    font-size: 18px;
}
.vc_tta-color-grey.vc_tta-style-classic .vc_tta-tab.vc_active>a {
    border-color: darken($gray, 10%) !important;
    border-bottom-color: $gray !important;
    /*border-top: $black 3px solid !important;*/
    background-color: $gray-lightest !important;
    color: $black !important;
    &:before {
    	height: 4px !important;
	    width: 101% !important;
	    background: $black;
	    content: '' !important;
	    left: -1px;
	    top: -1px !important;
    }
}
.vc_tta.vc_tta-spacing-1 .vc_tta-tab {
	margin-right: -2px !important;
}
.vc_tta.vc_tta-shape-rounded .vc_tta-panel-body, .vc_tta.vc_tta-shape-rounded .vc_tta-panel-heading {
	border-radius: 0px !important;
	background-color: $gray-lightest !important;
}
@media (min-width: 768px) {
	.vc_tta-color-grey.vc_tta-style-classic.vc_tta-tabs .vc_tta-panels {
	    background-color: $white !important;
	}
	.vc_tta-color-grey.vc_tta-style-classic.vc_tta-tabs .vc_tta-panels .vc_tta-panel-body {
		border-color: darken($gray, 10%) !important;
		padding: 40px;
	}
}
@media (max-width: 767px) {
	.vc_tta-color-grey.vc_tta-style-classic .vc_tta-panel .vc_tta-panel-title>a {
		text-transform: uppercase !important;
		border-color: darken($gray, 10%) !important;
	    background: $white !important;
	    color: $blue !important;
	    font-weight: 400 !important;
	}

	.vc_tta-color-grey.vc_tta-style-classic .vc_tta-panel.vc_active .vc_tta-panel-title>a {
		color: $black !important;
		font-weight: 700 !important;
		background: $gray !important;
	}
	.vc_tta-color-grey.vc_tta-style-classic .vc_tta-panel .vc_tta-panel-body {
	    background-color: $gray !important;
	}
	.vc_tta-color-grey.vc_tta-style-classic .vc_tta-panel .vc_tta-panel-body {
		border-color: darken($gray, 10%) !important;
	}
	.vc_tta-color-grey.vc_tta-style-classic .vc_tta-panel .vc_tta-panel-heading {
		border-color: darken($gray, 10%) !important;
	}
	.vc_tta-panel-heading {
		margin-bottom: -1px !important;
	}
	.vc_tta.vc_general .vc_tta-panel {
		margin-bottom: -1px !important;
	}
}


/*-- Custom Accordion --*/
.vc_tta-accordion {
	h4.vc_tta-panel-title {
		font-size: 18px;
		font-weight: 600;
	}
	.vc_tta-panel.vc_active .vc_tta-controls-icon {
		background: $black;
	}
	.vc_tta-controls-icon {
		background: $blue;
		width: 28px !important;
		height: 28px !important;
		z-index: 9;
	}
	.vc_tta-controls-icon.vc_tta-controls-icon-plus::before {
		border-color: $white !important;
		left: 9px !important;
		width: 10px !important;
	}
	.vc_tta-controls-icon.vc_tta-controls-icon-plus::after {
		border-color: $white !important;
		top: 9px !important;
		height: 10px !important;
		left: 14px !important;
	}
	.vc_tta-panel.vc_active .vc_tta-panel-body {
		background: $gray-lightest !important;
		padding-top: 20px !important;
		padding-bottom: 40px !important;
	}
	.wpb_content_element {
		padding: 0px 25px;
	}
	.vc_tta-panel-title>a {
		font-weight: 400 !important;
		text-transform: uppercase !important;
		padding: 14px 45px 14px !important;
		color: $blue !important;
		@media (max-width: 767px) {
			padding: 14px 60px 14px 20px !important;
		}
	}
	.vc_tta-panel.vc_active .vc_tta-panel-title>a {
		font-weight: 600 !important;
		color: $black !important;
		background: $gray-lightest !important;
		border-bottom: #e2e2e2 1px solid;
	}
	.vc_tta-panel .vc_tta-panel-heading {
		border-color: #e2e2e2 !important;
		border-color: darken($gray, 10%);
	    background: $light-yellow !important;
	}
	.vc_tta-panel .vc_tta-panel-body {
		border-color: #e2e2e2 !important;
		border-top: 1px solid transparent !important;
	}
	/*.vc_tta-panel.vc_active .vc_tta-panel-heading {
		background: #ffffff !important;
	}*/
	span.vc_tta-title-text {
		line-height: 24px;
	}
}
/*-- Alternate accordion color --*/
/*.vc_tta-accordion .vc_tta-panels .vc_tta-panel:nth-of-type(odd) {
    background-color: #ffffff !important;
}
.vc_tta-accordion .vc_tta-panels .vc_tta-panel:nth-of-type(even) {
    background-color: #eff3e7 !important;
}
.vc_tta-accordion .vc_tta-panels .vc_tta-panel:nth-of-type(odd) .vc_tta-panel-title>a{
	border-bottom: #e0e0e0 1px solid;
}*/
.vc_tta-accordion .vc_tta-panel .vc_tta-panel-heading {
	/*border-color: transparent !important;*/
    background-color: transparent !important;
}
.vc_tta-accordion .vc_tta-panel .vc_tta-panel-body {
	background-color: transparent !important;
}

/*-- Table --*/
.thead-light-yellow{
	th {
	    color: $black;
	    font-weight: 600;
	    background-color: $light-yellow;
	    border-color: $light-yellow !important;
	}
}